import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import "./pageStyles/contact.scss"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <div id="page-container" style={{ overflowY: 'hidden' }}>
        <div id="cont-boc et-boc" className="cont-boc et-boc">
          <div id="cont-main-area et-main-area">
            <div id="main-content">
              <article id="post-209" className="post-209 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="cont-l et-l cont-l--post et-l--post">
                    <div className="cont_builder_inner_content et_builder_inner_content cont_pb_gutters3 et_pb_gutters3">
                      <div className="cont_pb_section et_pb_section cont_pb_section_10 et_pb_section_10 cont_pb_with_background et_pb_with_background cont_section_regular et_section_regular section_has_divider cont_pb_bottom_divider et_pb_bottom_divider cont_pb_top_divider et_pb_top_divider optml-bg-lazyloaded">
                        <div className="cont_pb_row et_pb_row cont_pb_row_14 et_pb_row_14 optml-bg-lazyloaded">
                          <div className="cont_pb_column et_pb_column cont_pb_column_4_4 et_pb_column_4_4 cont_pb_column_23 et_pb_column_23  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough cont-last-child et-last-child">
                            <div className="cont_pb_module et_pb_module cont_pb_text et_pb_text cont_pb_text_14 et_pb_text_14 cont_pb_text_align_center et_pb_text_align_center cont_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="cont_pb_text_inner et_pb_text_inner">Contact</div>
                            </div>
                          </div>
                        </div>
                        <div className="cont_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
                      </div>
                      <div className="cont_pb_section et_pb_section cont_pb_section_11 et_pb_section_11 cont_section_regular et_section_regular">
                        <div className="cont_pb_row et_pb_row cont_pb_row_15 et_pb_row_15 optml-bg-lazyloaded">
                          <div className="cont_pb_column et_pb_column cont_pb_column_4_4 et_pb_column_4_4 cont_pb_column_24 et_pb_column_24  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough cont-last-child et-last-child">
                            <div className="cont_pb_module et_pb_module cont_pb_text et_pb_text cont_pb_text_15 et_pb_text_15 cont_pb_text_align_left et_pb_text_align_left cont_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="cont_pb_text_inner et_pb_text_inner"><h2>We would love to hear from you. Contact us by phone or through the contact form below.</h2></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cont_pb_section et_pb_section cont_pb_section_12 et_pb_section_12 cont_pb_with_background et_pb_with_background cont_section_regular et_section_regular optml-bg-lazyloaded">
                        <div className="cont_pb_row et_pb_row cont_pb_row_16 et_pb_row_16 cont_pb_row_fullwidth et_pb_row_fullwidth cont_pb_equal_columns et_pb_equal_columns cont_pb_gutters2 et_pb_gutters2 optml-bg-lazyloaded">
                          <div className="cont_pb_column et_pb_column cont_pb_column_1_3 et_pb_column_1_3 cont_pb_column_25 et_pb_column_25  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                          </div>
                          <div className="cont_pb_column et_pb_column cont_pb_column_1_3 et_pb_column_1_3 cont_pb_column_26 et_pb_column_26  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="cont_pb_module et_pb_module cont_pb_blurb et_pb_blurb cont_pb_blurb_11 et_pb_blurb_11 cont_pb_text_align_center et_pb_text_align_center cont_pb_blurb_position_top et_pb_blurb_position_top cont_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded cont_had_animation et_had_animation" style={{}} data-animation-style="fold" data-animation-repeat data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="30%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="cont_pb_blurb_content et_pb_blurb_content">
                                <div className="cont_pb_main_blurb_image et_pb_main_blurb_image">
                                  <span className="cont_pb_image_wrap et_pb_image_wrap">
                                    <span className="cart-icon-et cont-pb-icon et-pb-icon et-pb-icon-circle et-pb-icon-circle-border" />
                                  </span>
                                </div>
                                <div className="cont_pb_blurb_container et_pb_blurb_container">
                                  <h4 className="cont_pb_module_header et_pb_module_header">Orders</h4>
                                  <div className="cont_pb_blurb_description et_pb_blurb_description">
                                    <a href="tel:13128884488">
                                      <strong>312-888-4488</strong><br />
                                    </a>
                                    <strong>Fax:</strong> 866-897-3630<br />
                                    <a href="mailto:orders@typenex.com">orders@typenex.com</a></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cont_pb_column et_pb_column cont_pb_column_1_3 et_pb_column_1_3 cont_pb_column_27 et_pb_column_27  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough cont-last-child et-last-child">
                          </div>
                        </div>
                        <div className="cont_pb_row et_pb_row cont_pb_row_17 et_pb_row_17 optml-bg-lazyloaded">
                          <div className="cont_pb_column et_pb_column cont_pb_column_4_4 et_pb_column_4_4 cont_pb_column_28 et_pb_column_28  cont_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough cont-last-child et-last-child">
                            <div className="cont_pb_module et_pb_module cont_pb_text et_pb_text cont_pb_text_16 et_pb_text_16 cont_pb_text_align_left et_pb_text_align_left cont_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="cont_pb_text_inner et_pb_text_inner"><h1>How can we help?</h1></div>
                            </div>
                            <div className="cont_pb_module et_pb_module cont_pb_text et_pb_text cont_pb_text_17 et_pb_text_17 cont_pb_text_align_left et_pb_text_align_left cont_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="cont_pb_text_inner et_pb_text_inner">
                                <div className="gf_browser_gecko gform_wrapper gform_legacy_markup_wrapper" id="gform_wrapper_2"><div id="gf_2" className="gform_anchor" tabIndex={-1} />
                                </div>
                                <div className="contact-button-wrapper cont_pb_button_module_wrapper et_pb_button_module_wrapper cont_pb_button_16_wrapper et_pb_button_alignment_center et_pb_module optml-bg-lazyloaded">
                                  <a href="https://www.typenex.com/contact/ARC%20Medical" className="contact-button et_pb_button et_pb_button_16 et_hover_enabled et_pb_bg_layout_dark" data-et-has-event-already="true">
                                    Contact Us
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage